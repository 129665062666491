<template>
  <div>
    <div
      class="
        container-fluid
        header-content
        pos-relative
        height-res-tab-small-300 height-res-200
      "
    >
      <div class="container-standar pos-relative h-100 h-res-tab-small-80">
        <div class="pos-absolute bottom-10">
          <h4 class="text-white font-28 font-weight-6 mb-2">Berita</h4>
          <a class="font-14 text-white font-weight-6" href="/">Home /</a
          ><a class="font-14 text-white" href="#"> Berita</a>
        </div>
      </div>
    </div>
    <div class="container-standar pb-100 mt-40 mt-res-tab-small-10">
      <div class="news">
        <b-row>
          <b-col
            cols="12"
            lg="4"
            md="6"
            sm="12"
            v-for="(berita, index) in news"
            :key="index"
          >
            <b-link :to="'/berita/' + berita.slug">
              <b-card
                border-variant="transparent"
                class="suggest-img"
                v-b-hover="handleHover"
                @mouseover="mouseOver(index)"
                :class="hover && i == index ? 'shadow p-0' : 'p-0'"
                :style="
                  hover && i == index
                    ? 'transform: scale(1.1); transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1); border-radius: 20px;'
                    : 'border-0'
                "
              >
                <b-row>
                  <b-col cols="12">
                    <b-img
                      style="
                        border-radius: 20px;
                        height: 300px;
                        width: 100%;
                        object-fit: cover;
                      "
                      :src="berita.picture_path"
                    ></b-img>
                    <b-badge class="my-3" href="#" variant="primary"
                      >Category</b-badge
                    >
                    - <span>{{ berita.created }}</span> <br />
                    <span class="font-17 font-weight-7">{{
                      berita.title | shotDescription(50)
                    }}</span>
                    <br />
                    <p>{{ berita.content | shotDescription(100) }}</p>
                    <b-avatar
                      src="@/assets/images/avatar.png"
                      size="2rem"
                      class="mt-3 mr-2"
                    ></b-avatar>
                    <span class="font-16 font-weight-4"> Admin</span><br />
                  </b-col>
                </b-row>
              </b-card>
            </b-link>
          </b-col>
        </b-row>
        <!-- <ul class="news-sub">
          <li v-b-hover v-for="(berita, index) in news" :key="index">
            <router-link :to="`berita/${berita.id}`" v-bind:key="index">
            <div class="shadow overflow-hidden  card border-radius-10">
                  <div class="img-content h-res-tab-big-200 ">
                    <img   :src="berita.picture_path"  alt="">
                  <div class="title">
                    <span class="text-white font-20 font-res-tab-big-16 width-70 w-res-tab-big-100">{{ berita.title }}</span>
                  </div>
                  </div>
                  <div class="content pad-10">
                    <p class="text-grey">{{ berita.content | shotDescription }}</p>
                    <span class="d-block w-100 text-primary font-weight-5 mt-5">{{ berita.publish_date }}</span>
                  </div>
            </div>
              </router-link>
          </li>
            
        </ul> -->
      </div>
      <div
        class="
          flex
          width-100
          justify-center
          text-center
          mt-50
          pb-res-tab-small-40
        "
        v-if="news.length != allnews.length"
      >
        <span
          @click="news = allnews"
          class="
            d-block
            width-30
            w-res-100 w-res-tab-small-80
            text-center
            cursor-pointer
            border-2
            text-primary
            font-weight-6 font-18
            pad-10
            bc-primary
            border-radius-100
            radius-100
            font-weight-7
          "
          >See More
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// import * as moment from 'moment'
// import stripHtml from "string-strip-html";
export default {
  data() {
    return {
      news: [],
      allnews: [],
      hover: false,
      i: null,
    };
  },
  mounted() {
    this.getBerita();
  },
  methods: {
    getBerita() {
      this.$axios.get("/news").then((response) => {
        this.allnews = response.data.data;
        this.news = response.data.data.filter((value, index) => index < 3);
      });
    },
    routerPush(newVal) {
      this.$router.push(newVal);
    },
    handleHover(isHovered) {
      if (isHovered) {
        this.hover = true;
      } else {
        this.hover = false;
      }
    },
    mouseOver(i) {
      this.i = i;
    },
  },
};
</script>
